import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isPageDynamic as getIsPageDynamic } from './is-page-dynamic';
import { getDynamicSection } from './get-dynamic-section';
import { resolveWidgetPath } from './resolve-widget-path';
import { createWidgetRegistryService } from '~/services/widget-registry-service';

export const registerWidget = async (
  { environment, httpClient, controllerConfig, errorMonitor }: ControllerFlowAPI,
  { resourceId, categoryId, pageId }: { resourceId?: string; pageId?: string; categoryId?: string },
) => {
  let urlPath: string | undefined;
  const widgetRegistryService = createWidgetRegistryService({ httpClient });
  if (!resourceId || !categoryId || environment.isEditor || environment.isPreview) {
    return;
  }

  const { wixCodeApi, compId } = controllerConfig;
  const isPageDynamic = await getIsPageDynamic(wixCodeApi);

  try {
    urlPath = resolveWidgetPath(wixCodeApi);
  } catch (e) {
    if (e instanceof Error) {
      errorMonitor?.captureException(e, { tags: { context: 'registerWidget' } });
    }
  }

  try {
    await widgetRegistryService.registerWidget({
      resourceId,
      categoryId,
      componentId: compId,
      pageId,
      ...(isPageDynamic ? { dynamicPageTitle: getDynamicSection(wixCodeApi.location) } : {}),
      urlPath,
    });
  } catch (e) {
    if (e instanceof Error) {
      errorMonitor?.captureException(e, { tags: { context: 'registerWidget' } });
    }
  }
};
