import { isEncodedURI } from './generate-deep-link';

export const getIsDeepLinked = (locationQuery: Record<string, string>, resourceId?: string) => {
  const normalisedResourceId =
    resourceId && isEncodedURI(resourceId) ? decodeURIComponent(resourceId) : resourceId;
  const normalisedLocationResourceId = isEncodedURI(locationQuery.resourceId)
    ? decodeURIComponent(locationQuery.resourceId)
    : locationQuery.resourceId;

  return Boolean(normalisedLocationResourceId === normalisedResourceId && locationQuery.commentId);
};
