import {
  Category,
  WidgetSettingsService,
  SortOrder,
} from '../../../services/widget-settings-service';

export const getSettingsCategory = async ({
  widgetSettingsService,
  targetCategoryId,
}: {
  widgetSettingsService: WidgetSettingsService;
  targetCategoryId?: string;
}): Promise<Category | undefined> => {
  try {
    let category: Category | undefined;

    if (targetCategoryId) {
      category = await widgetSettingsService.getCategory(targetCategoryId);
    } else {
      const categories = await widgetSettingsService.queryCategories({
        sort: [{ fieldName: 'createdDate', order: SortOrder.ASC }],
      });

      category =
        categories.filter((category) => category.id === targetCategoryId)[0] ?? categories[0];
    }

    return category;
  } catch (e) {
    throw e instanceof Error ? e : new Error('Failed to get settings category');
  }
};
